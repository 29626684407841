<template>
  <b-sidebar
    id="add-new-label-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="
      $refs.refFormObserver && $refs.refFormObserver.flags.dirty
    "
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2 add-edit-sidebar-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Label Name -->
          <validation-provider
            #default="validationContext"
            name="Label Name"
            rules="required"
          >
            <b-form-group label="Label Name" label-for="label">
              <b-form-input
                id="label"
                v-model="form.label"
                maxlength="255"
                autofocus
                :state="getValidationState(validationContext)"
                :plaintext="isReadonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="mt-2 fixed-footer-form-actions d-flex">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mr-2"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

            <b-button
              v-if="!isReadonly"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="submit"
              variant="primary"
              @click="hide"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum, email } from '@validations'
  import Ripple from 'vue-ripple-directive'
  // import vSelect from 'vue-select'
  import { mapState } from 'vuex'
  import countries from '@/@fake-db/data/other/countries'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      // vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isSidebarActive',
      event: 'update:is-sidebar-active',
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      isReadonly: {
        type: Boolean,
      },
      value: {
        type: Object,
        default: () => this.getDefaultForm(),
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        form: this.getDefaultForm(),
        btns: ['Save', 'Save & Close'],
        submitState: '',
      }
    },
    computed: {
      ...mapState('app', ['isLoading']),
      isEditing() {
        return !!this.value
      },
      title() {
        if (this.isReadonly) {
          return 'Label Details'
        }
        return this.isEditing ? 'Edit Label' : 'Add New Label'
      },
    },
    watch: {
      value(value) {
        if (value) {
          this.form = {
            ...value,
            __typename: undefined,
          }
          delete this.form.processes
        }
      },
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      resetForm() {
        this.$emit('update:value', this.getDefaultForm())
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
      },
      onSubmit() {
        const action = this.isEditing
          ? this.$store.dispatch('label/updateLabel', this.form)
          : this.$store.dispatch('label/addLabel', this.form)

        action.then(() => {
          this.$emit('update:is-sidebar-active', false)
        })
      },
      getDefaultForm() {
        return {
          label: '',
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '../../@core/scss/vue/libs/vue-select';

  #add-new-label-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .fixed-sidebar-header {
    position: fixed;
    width: 100%;
    z-index: 10000;
  }

  .add-edit-sidebar-form {
    margin-top: 40px;
    margin-bottom: 52px;
    padding-top: 12px;
  }

  .fixed-footer-form-actions {
    position: fixed;
    bottom: 0;
    padding: 16px 58px 16px 0;
    width: 100%;
    background-color: white;
    z-index: 10000;
  }
</style>
